import { getUserInfo, setUserInfo, removeUserInfo, formatDate } from "../utils";

const BEARER_TOKEN = "Bearer ";
const TOKEN_EXPIRED_MSG = "ID token has expired at";
const TOKEN_NOT_VALID_CODE = "TOKEN_NOT_VALID";

const SIGNUP_URL = "/signup";
const SIGNUP_MERCHANT_URL = "/signup/gdsp/merchant";
const SEARCH_MERCHANT_URL = "/search/business";
const SEARCH_MERCHANT_PUBLIC_URL = "/public/search/business";
const GET_MERCHANT_URL = "/business/{business}";
const GET_MERCHANT_PUBLIC_URL = "/public/business/{business}";
const COMPLETE_SIGNUP_URL = "/signup/gdsp/complete";
const GET_USER_URL = "/users/{user}";

const GET_USER_STATS_URL = "/data/campaigns/default/users/points";
const GET_MERCHANT_STATS_URL = "/data/campaigns/default/merchants/actions";
const GET_USER_STATS_COUNT_URL = "/data/campaigns/default/users/points/count";
const GET_MERCHANT_STATS_COUNT_URL =
  "/data/campaigns/default/merchants/actions/count";
const DOWNLOAD_PRIZE_STATS = "/data/campaigns/default/users/prizes/csv";
const GET_USER_PRODUCT_DEFAULT_URL = "/users/{user}/products/default";
const GET_ALL_REWARDING_ACTIONS_URL = "/users/{user}/rewardingactions";

const CREATE_REWARDING_ACTION_URL = "/gdsp/business/rewardingaction";
const GET_REWARDING_ACTION_QRCODE_URL = "/gdsp/actions/{raid}/qrcode";
const GET_FAIR_REWARDING_ACTION_QRCODE_URL =
  "/gdsp/actions/fair/{fair-key}/qrcode";
const GET_BUSINESS_REWARDING_ACTIONS_URL =
  "/gdsp/business/{business}/rewardingaction";
const GET_PROFILE_PICTURE_UPLOAD_URL_URL = "/users/{user}/picture/upload-url";
const GET_USER_LOGGED_URL = "/me";
const GET_BUSINESS_URL = "/users/{user}/business";
const GET_UPLOAD_CONTENTS_URL = "/tenant/contents/upload-url";
const UPDATE_CONTENT_URL = "/tenant/contents/{content}";
const DELETE_CONTENT_URL = "/tenant/contents/{content}";
const GET_CONTENT_URL = "/tenants/GDSP/contents/{content}";
//const GET_CONTENTS_URL = "/tenant/contents";
const GET_CONTENTS_URL = "/tenant/contents";
const GET_CONTENTS_PUBLIC_URL = "/public/tenant/contents";
const UPDATE_CONTENT_STATUS_URL = "/tenant/contents/{content}/{status}";
const GET_PRODUCT_CAMPAIGN_PRIZES_URL =
  "/campaigns/{campaign}/products/{product}/prizes";
const CONFIRM_ACTION_URL = "/gdsp/business/{business}/action/confirm";
const CANCEL_ACTION_URL = "/gdsp/business/{business}/action/cancel";
const GET_ACTION_FROM_QRCODE_URL = "/gdsp/actions/qrcode/{code}/info";
const GET_FAIR_FROM_QRCODE_URL = "/gdsp/actions/qrcode/{code}/fair";
const GET_REVIEWS_URL = "/tenant/actions?type=REVIEW";
const UPDATE_ACTION_STATUS_URL = "/tenant/actions/{action}/status/{status}";
const ISSUE_PRODUCT_PRIZE_URL = "/products/card/{card}/prize/{prize}";
const PRODUCTS_URL = "/users/{user}/products";
const PRODUCT_QRCODE_URL = "/products/{product}/qrcode";
const GET_ALL_MERCHANT_GEOPOINTS = "/search/business/all/geopoints";
const GET_ALL_MERCHANT_GEOPOINTS_PUBLIC =
  "/public/search/business/all/geopoints";
const CONTACT_SUPPORT_EMAIL_URL = "/tenant/support/email";
const GET_BUSINESS_REVIEW_URL = "/gdsp/business/{business}/actions/reviews";
const GET_BUSINESS_REVIEW_PUBLIC_URL =
  "/public/gdsp/business/{business}/actions/reviews";
const BURN_COUPON_URL = "/products/coupon/{qr-code}/burn";
const GET_CAMPAIGNS_URL = "/business/{business}/campaigns";
const GET_CAMPAIGN_PERFORMANCE_URL =
  "/business/{business}/campaigns/{campaign}/performance";
const GET_PRODUCT_INFO_URL = "/business/{business}/products/{qr-code}/info";
const BURN_AMAZON_COUPON_URL = "/products/amazon/{coupon}/self/burn";
const GET_AMAZON_GIFTCARD_INFO_URL = "/products/amazon/{coupon}/giftcard";
const DOWNLOAD_USER_STATS = "/data/campaigns/default/users/points/csv";
const DOWNLOAD_MERCHANT_STATS = "/data/campaigns/default/merchants/actions/csv";
const DISABLE_USER_URL = "/users/{user}/aud/{aud}/disable";
const ENABLE_USER_URL = "/users/{user}/aud/{aud}/enable";
const GET_MERCHANT_BY_TYPE = "/search/business/type/{type}";
const GET_MERCHANT_BY_TYPE_PUBLIC = "/public/search/business/type/{type}";
const CREATE_ENTRY_REWARDING_ACTION_URL =
  "/gdsp/business/{business}/rewardingaction/entry";
const GET_PRIZE_STATS_URL = "/data/campaigns/default/users/prizes";
const GET_GENLIST_CATEGORIES_URL = "/tenant/genlists/business_category/view/it";
const SEARCH_BUSINESS_BY_CONTENT_PUBLIC_URL =
  "/public/search/business/content/{content}";
const GET_EVENTS_URL = "/tenant/{tenant}/events";
const GET_BUSINESS_CONTENT_SLOTS_URL =
  "/business/{business}/content/{content}/available-slots";
const POST_BUSINESS_ECOM_ORDERS_URL = "/business/{business}/ecomorders";
const GET_ECOM_ORDERS_URL = "/users/{user}/ecomorders?content={content}";
const GET_CONTACT_EMAIL_URL = "/user/sendmail/quote";
const POST_EVENT_CONFIRM_URL = "/campaigns/{campaign}/issueproduct";
const GET_EVENTS_CONFIRMED_URL = "/users/{user}/products";
const DELETE_RESERVATION_URL = "/order/{order}";

function handleResponse(response) {
  return new Promise(function(resolve, reject) {
    if (!response.ok) {
      response
        .json()
        .then((data) => {
          reject(JSON.stringify(data));
        })
        .catch(() => {
          reject(new Error(response.statusText));
        });
    } else {
      resolve(response.json());
    }
  });
}

function handleDownload(response) {
  if (!response.ok) {
    createError(response.statusText);
  } else {
    return response
      .blob()
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.target = "_blank";
        link.href = url;
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        createError(e);
      });
  }
}

function createError(error) {
  console.log("ERROR: ", error);
  if (error instanceof Error) {
    let apiError = {
      message: error.message,
      http_code: 500,
      error_code: "UNKNOW",
    };

    return apiError;
  } else {
    let apiError;
    try {
      if (error.hasOwnProperty("http_code")) {
        apiError = {
          message: error.Message,
          http_code: error.HTTPCode,
          error_code: error.Code,
        };
      } else {
        let bmarkenErr = JSON.parse(error);
        apiError = {
          message: bmarkenErr.Message,
          http_code: bmarkenErr.HTTPCode,
          error_code: bmarkenErr.Code,
        };
      }
      return apiError;
    } catch (error) {
      console.log("CATCH: ", error);
      apiError = {
        message: error,
        http_code: "500",
        error_code: "UNKNOW-JS",
      };
      return apiError;
    }
  }
}

class BmarkenAPI {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL;
    this.defaultClientID = process.env.REACT_APP_GDSP_CLIENT_ID;
    this.defaultTenantID = process.env.REACT_APP_GDSP_TENANT_ID;
    this.merchantClientID = process.env.REACT_APP_GDSP_MERCHANT_CLIENT_ID;

    this.signupURL = this.baseURL + SIGNUP_URL;
    this.signupMerchantURL = this.baseURL + SIGNUP_MERCHANT_URL;
    this.searchMerchantURL = this.baseURL + SEARCH_MERCHANT_URL;
    this.searchMerchantPublicURL = this.baseURL + SEARCH_MERCHANT_PUBLIC_URL;
    this.getMerchantURL = this.baseURL + GET_MERCHANT_URL;
    this.getMerchantPublicURL = this.baseURL + GET_MERCHANT_PUBLIC_URL;
    this.completeSignupURL = this.baseURL + COMPLETE_SIGNUP_URL;

    this.createRewardingActionURL = this.baseURL + CREATE_REWARDING_ACTION_URL;
    this.getUserProductDefaultURL = this.baseURL + GET_USER_PRODUCT_DEFAULT_URL;
    this.getAllRewardingActionsURL =
      this.baseURL + GET_ALL_REWARDING_ACTIONS_URL;
    this.getRewadingActionQRCodeURL =
      this.baseURL + GET_REWARDING_ACTION_QRCODE_URL;
    this.getFairRewadingActionQRCodeURL =
      this.baseURL + GET_FAIR_REWARDING_ACTION_QRCODE_URL;
    this.getProfilePictureUploadUrlURL =
      this.baseURL + GET_PROFILE_PICTURE_UPLOAD_URL_URL;
    this.getUserLoggedUrl = this.baseURL + GET_USER_LOGGED_URL;
    this.getBusinessRewardingActionsURL =
      this.baseURL + GET_BUSINESS_REWARDING_ACTIONS_URL;
    this.getBusinessURL = this.baseURL + GET_BUSINESS_URL;
    this.getUserURL = this.baseURL + GET_USER_URL;
    this.getUploadContentsURL = this.baseURL + GET_UPLOAD_CONTENTS_URL;
    this.deleteContentURL = this.baseURL + DELETE_CONTENT_URL;
    this.getContentURL = this.baseURL + GET_CONTENT_URL;
    this.updateContentURL = this.baseURL + UPDATE_CONTENT_URL;
    this.getContentsURL = this.baseURL + GET_CONTENTS_URL;
    this.getContentsPublicURL = this.baseURL + GET_CONTENTS_PUBLIC_URL;
    this.updateContentStatusURL = this.baseURL + UPDATE_CONTENT_STATUS_URL;
    this.getProductCampaignPrizesURL =
      this.baseURL + GET_PRODUCT_CAMPAIGN_PRIZES_URL;
    this.issueProductPrizeURL = this.baseURL + ISSUE_PRODUCT_PRIZE_URL;
    this.getConfirmActionURL = this.baseURL + CONFIRM_ACTION_URL;
    this.getCancelActionURL = this.baseURL + CANCEL_ACTION_URL;
    this.getActionByQRCodeURL = this.baseURL + GET_ACTION_FROM_QRCODE_URL;
    this.getFairByQRCodeURL = this.baseURL + GET_FAIR_FROM_QRCODE_URL;
    this.getReviewsURL = this.baseURL + GET_REVIEWS_URL;
    this.updateActionStatusURL = this.baseURL + UPDATE_ACTION_STATUS_URL;
    this.productsURL = this.baseURL + PRODUCTS_URL;
    this.getProductQrcodeURL = this.baseURL + PRODUCT_QRCODE_URL;
    this.getAllMerchantGeoPointsURL = this.baseURL + GET_ALL_MERCHANT_GEOPOINTS;
    this.getAllMerchantGeoPointsPublicURL =
      this.baseURL + GET_ALL_MERCHANT_GEOPOINTS_PUBLIC;
    this.contactSupportEmailURL = this.baseURL + CONTACT_SUPPORT_EMAIL_URL;
    this.getMerchantReviewsURL = this.baseURL + GET_BUSINESS_REVIEW_URL;
    this.getMerchantReviewsPublicURL =
      this.baseURL + GET_BUSINESS_REVIEW_PUBLIC_URL;
    this.getBurnCouponURL = this.baseURL + BURN_COUPON_URL;
    this.getCampaignsURL = this.baseURL + GET_CAMPAIGNS_URL;
    this.getCampaignPerformanceURL =
      this.baseURL + GET_CAMPAIGN_PERFORMANCE_URL;
    this.getProductInfoURL = this.baseURL + GET_PRODUCT_INFO_URL;
    this.getBurnAmazonCouponURL = this.baseURL + BURN_AMAZON_COUPON_URL;
    this.getAmazonGiftcardInfoURL = this.baseURL + GET_AMAZON_GIFTCARD_INFO_URL;
    this.getUserStatsWithPointsURL = this.baseURL + GET_USER_STATS_URL;
    this.getUserStatsWithPointsCountURL =
      this.baseURL + GET_USER_STATS_COUNT_URL;
    this.getPrizeStatsURL = this.baseURL + GET_PRIZE_STATS_URL;

    this.getMerchantStatsWithActionsURL = this.baseURL + GET_MERCHANT_STATS_URL;
    this.getMerchantStatsWithActionsCountURL =
      this.baseURL + GET_MERCHANT_STATS_COUNT_URL;
    this.getDownloadMerchantStatURL = this.baseURL + DOWNLOAD_MERCHANT_STATS;
    this.downloadPrizeStatsURL = this.baseURL + DOWNLOAD_PRIZE_STATS;

    this.getDisableUserURL = this.baseURL + DISABLE_USER_URL;
    this.getEnableUserURL = this.baseURL + ENABLE_USER_URL;
    this.getDownloadStatURL = this.baseURL + DOWNLOAD_USER_STATS;
    this.getMerchantByTypeURL = this.baseURL + GET_MERCHANT_BY_TYPE;
    this.getMerchantByTypePublicURL =
      this.baseURL + GET_MERCHANT_BY_TYPE_PUBLIC;
    this.createEntryRewardingActionURL =
      this.baseURL + CREATE_ENTRY_REWARDING_ACTION_URL;
    this.getGenListCategoriesURL = this.baseURL + GET_GENLIST_CATEGORIES_URL;
    this.searchBusinessByContentPublicURL =
      this.baseURL + SEARCH_BUSINESS_BY_CONTENT_PUBLIC_URL;
    this.getEventsByCampaignURL = this.baseURL + GET_EVENTS_URL;
    this.getBusinessContentSlotsURL =
      this.baseURL + GET_BUSINESS_CONTENT_SLOTS_URL;
    this.postBusinessEcomOrdersURL =
      this.baseURL + POST_BUSINESS_ECOM_ORDERS_URL;
    this.getEcomOrdersURL = this.baseURL + GET_ECOM_ORDERS_URL;
    this.postContactEmailURL = this.baseURL + GET_CONTACT_EMAIL_URL;
    this.postEventConfirmURL = this.baseURL + POST_EVENT_CONFIRM_URL;
    this.getEventsConfirmedURL = this.baseURL + GET_EVENTS_CONFIRMED_URL;
    this.deleteReservationURL = this.baseURL + DELETE_RESERVATION_URL;
  }

  // *** UTILS ***
  checkTokenExpired = (error) => {
    if (!(error instanceof Error)) {
      let data = JSON.parse(error);
      if (data.HTTPCode === 401 && data.Code === TOKEN_NOT_VALID_CODE) {
        console.error("Expired Token");
        if (data.Message.indexOf(TOKEN_EXPIRED_MSG) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // *** Token utils ***
  logout = () => {
    removeUserInfo();
  };

  saveToken = (token) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonObj = JSON.parse(window.atob(base64));

    //console.log("TOKEN: ", jsonObj)

    let bme = getUserInfo();
    bme.user_email = jsonObj.email;
    bme.token_info = jsonObj;
    bme.firebase_token_id = token;
    bme.user_id = jsonObj.bme_id;
    bme.role = jsonObj.r;
    setUserInfo(bme);
  };

  getToken = () => {
    let bme = getUserInfo();
    return bme.firebase_token_id;
  };

  checkIfLoggedIn = () => {
    let tokenInfo = this.getTokenInfo();
    return tokenInfo != null && tokenInfo.bme_id != null;
  };

  getTokenInfo = () => {
    let bme = getUserInfo();
    let userLoggedInfo = bme.token_info;
    if (userLoggedInfo && userLoggedInfo !== {}) {
      return userLoggedInfo;
    }

    return null;
  };

  signupMerchant = (email, password) => {
    let url = this.signupMerchantURL;

    let body = JSON.stringify({
      email: email,
      password: password,
      client_id: this.merchantClientID,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  // *** Bmarken API ***
  signup = (token) => {
    let url = this.signupURL;
    let clientID = this.defaultClientID;
    const loginMerchant = localStorage.getItem("LoginMerchant");
    if (
      loginMerchant !== undefined &&
      loginMerchant !== null &&
      loginMerchant
    ) {
      localStorage.removeItem("LoginMerchant");
      clientID = this.merchantClientID;
    }

    let body = JSON.stringify({
      token_id: token,
      client_id: clientID,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  sendSupportEmail = (content, getNewTokenCallback) => {
    let url = this.contactSupportEmailURL;
    const tokenID = this.getToken();

    const sendSupportEmail = this.sendSupportEmail;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    const body = JSON.stringify({
      content: content,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return sendSupportEmail(content, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getBlueTorinoCode = (code, getNewTokenCallback) => {
    return new Promise(function(resolve, reject) {
      const code = {
        code: "CXX9DZHTHTPE",
      };
      resolve(code);
    });
  };

  getProductInfo = (code, businessID, getNewTokenCallback) => {
    const url = this.getProductInfoURL
      .replace("{business}", businessID)
      .replace("{qr-code}", code);
    const tokenID = this.getToken();

    const getProductInfo = this.getProductInfo;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      //non controllo in cache

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getProductInfo(code, businessID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getMerchantStatsWithActionsCount = (getNewTokenCallback) => {
    let url = this.getMerchantStatsWithActionsCountURL;
    const tokenID = this.getToken();

    const getMerchantStatsWithActionsCount = this
      .getMerchantStatsWithActionsCount;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getMerchantStatsWithActionsCount(null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getUserStatsWithPointsCount = (email, getNewTokenCallback) => {
    let url = this.getUserStatsWithPointsCountURL;
    const tokenID = this.getToken();

    const getUserStatsWithPointsCount = this.getUserStatsWithPointsCount;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      if (email && email != "") {
        url = url + "?email=" + email;
      }

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getUserStatsWithPointsCount(null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getMerchantStatsWithActions = (page, qty, orderBy, getNewTokenCallback) => {
    let url = this.getMerchantStatsWithActionsURL;
    const tokenID = this.getToken();

    const getMerchantStatsWithActions = this.getMerchantStatsWithActions;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    url = url + "?page=" + page;
    url = url + "&qty=" + qty;
    url = url + "&orderBy=" + orderBy;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getMerchantStatsWithActions(page, qty, orderBy, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  downloadPrizeStats = (getNewTokenCallback) => {
    let url = this.downloadPrizeStatsURL;
    const tokenID = this.getToken();

    const downloadPrizeStats = this.downloadPrizeStats;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "text/csv",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((data) => {
          return handleDownload(data);
        })
        .then(resolve)
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return downloadPrizeStats(null);
              })
              .then(resolve)
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getPrizeStats = (page, qty, orderBy, getNewTokenCallback) => {
    let url = this.getPrizeStatsURL;
    const tokenID = this.getToken();

    const getPrizeStats = this.getPrizeStats;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    url = url + "?page=" + page;
    url = url + "&qty=" + qty;
    url = url + "&orderBy=" + orderBy;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getPrizeStats(page, qty, orderBy, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getUserStatsWithPoints = (page, qty, orderBy, email, getNewTokenCallback) => {
    let url = this.getUserStatsWithPointsURL;
    const tokenID = this.getToken();

    const getUserStatsWithPoints = this.getUserStatsWithPoints;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    url = url + "?page=" + page;
    url = url + "&qty=" + qty;
    url = url + "&orderBy=" + orderBy;

    if (email && email != "") {
      url = url + "&email=" + email;
    }

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getUserStatsWithPoints(page, qty, orderBy, email, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getCampaigns = (businessID, getNewTokenCallback) => {
    const url = this.getCampaignsURL.replace("{business}", businessID);
    const tokenID = this.getToken();

    const getCampaigns = this.getCampaigns;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getCampaigns(businessID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getCampaignPerformance = (campaignID, businessID, getNewTokenCallback) => {
    const url = this.getCampaignPerformanceURL
      .replace("{business}", businessID)
      .replace("{campaign}", campaignID);
    const tokenID = this.getToken();

    const getCampaignPerformance = this.getCampaignPerformance;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getCampaignPerformance(campaignID, businessID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  burnCoupon = (businessID, code, getNewTokenCallback) => {
    let url = this.getBurnCouponURL.replace("{qr-code}", code);
    const tokenID = this.getToken();

    const burnCoupon = this.burnCoupon;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    const body = JSON.stringify({
      business_id: businessID,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return burnCoupon(businessID, code, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  updateActionStatus = (actionID, status, getNewTokenCallback) => {
    let url = this.updateActionStatusURL
      .replace("{action}", actionID)
      .replace("{status}", status);
    const tokenID = this.getToken();

    const updateActionStatus = this.updateActionStatus;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return updateActionStatus(actionID, status, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getReviews = (status, getNewTokenCallback) => {
    let url = this.getReviewsURL;
    const tokenID = this.getToken();
    //console.log(tokenID)

    const getReviews = this.getReviews;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    if (status && status !== null) url = url + "&status=" + status;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getReviews(status, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getMerchantReviews = (merchantID, status, getNewTokenCallback) => {
    let url = this.getMerchantReviewsURL.replace("{business}", merchantID);
    const tokenID = this.getToken();

    const getMerchantReviews = this.getMerchantReviews;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    if (status && status !== null) url = url + "?status=" + status;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getMerchantReviews(merchantID, status, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getMerchantReviewsPublic = (merchantID, status) => {
    let url = this.getMerchantReviewsPublicURL.replace(
      "{business}",
      merchantID
    );
    url = url + "?tenant=" + this.defaultTenantID;

    if (status && status !== null) url = url + "&status=" + status;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getMerchantByType = (type, getNewTokenCallback) => {
    let url = this.getMerchantByTypeURL.replace("{type}", type);
    const tokenID = this.getToken();

    const getMerchantByType = this.getMerchantByType;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getMerchantByType(type, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getMerchantByTypePublic = (type) => {
    let url = this.getMerchantByTypePublicURL.replace("{type}", type);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getBusiness = (userID, getNewTokenCallback) => {
    let url = this.getBusinessURL.replace("{user}", userID);
    const tokenID = this.getToken();
    //console.log(tokenID)

    const getBusiness = this.getBusiness;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getBusiness(userID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  searchMerchants = (
    lat,
    lng,
    r,
    name,
    prov,
    category,
    getNewTokenCallback
  ) => {
    let url = this.searchMerchantURL;
    const tokenID = this.getToken();

    const searchMerchants = this.searchMerchants;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    //gestione parametri
    if (lat && lat !== null) url = url + "&lat=" + lat;
    if (lng && lng !== null) url = url + "&lng=" + lng;
    if (r && r !== null) url = url + "&r=" + r;
    if (name && name !== null) url = url + "&name=" + name;
    if (prov && prov !== null) url = url + "&prov=" + prov;
    if (category && category !== null) url = url + "&category=" + category;

    //sostituisco il primo & con un ?
    const i = url.indexOf("&");
    if (i > -1) url = url.substr(0, i) + "?" + url.substr(i + 1);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return searchMerchants(lat, lng, r, name, prov, category, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  searchMerchantsPublic = (lat, lng, r, name, prov, category) => {
    let url = this.searchMerchantPublicURL;

    //gestione parametri
    if (lat && lat !== null) url = url + "&lat=" + lat;
    if (lng && lng !== null) url = url + "&lng=" + lng;
    if (r && r !== null) url = url + "&r=" + r;
    if (name && name !== null) url = url + "&name=" + name;
    if (prov && prov !== null) url = url + "&prov=" + prov;
    if (category && category !== null) url = url + "&category=" + category;

    url = url + "&tenant=" + this.defaultTenantID;

    //sostituisco il primo & con un ?
    const i = url.indexOf("&");
    if (i > -1) url = url.substr(0, i) + "?" + url.substr(i + 1);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getAllMerchantGeoPoints = (getNewTokenCallback) => {
    let url = this.getAllMerchantGeoPointsURL;

    const tokenID = this.getToken();

    const getAllMerchantGeoPoints = this.getAllMerchantGeoPoints;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getAllMerchantGeoPoints(null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getAllMerchantGeoPointsPublic = () => {
    let url = this.getAllMerchantGeoPointsPublicURL;
    url = url + "?tenant=" + this.defaultTenantID;
    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getMerchant = (merchantID, getNewTokenCallback) => {
    let url = this.getMerchantURL;
    url = url.replace("{business}", merchantID);

    const tokenID = this.getToken();

    const getMerchant = this.getMerchant;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getMerchant(merchantID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getMerchantPublic = (merchantID) => {
    let url = this.getMerchantPublicURL;
    url = url.replace("{business}", merchantID);
    url = url + "?tenant=" + this.defaultTenantID;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getUserLogged = (getNewTokenCallback) => {
    const url = this.getUserLoggedUrl;
    const getUserLogged = this.getUserLogged;
    const tokenExpired = this.checkTokenExpired;
    const tokenID = this.getToken();
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getUserLogged(null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  completeSignup = (
    token,
    nome,
    cognome,
    telefono,
    dataNozze,
    provinciaNozze,
    checkPrivacy,
    checkRegolamento,
    checkMarketing
  ) => {
    const url = this.completeSignupURL;
    const getToken = this.getToken;

    const body = JSON.stringify({
      name: nome,
      last_name: cognome,
      telephone: telefono,
      wedding_date: dataNozze ? dataNozze : "", //formatDate(dataNozze),
      wedding_province: provinciaNozze,
      privacy: checkPrivacy,
      rules: checkRegolamento,
      marketing: checkMarketing,
    });

    if (token !== null) {
      const options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
        body: body,
      };

      return new Promise(function(resolve, reject) {
        fetch(url, options)
          .then((resp) => {
            return handleResponse(resp);
          })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(createError(error));
          });
      });
    } else {
      return new Promise(function(resolve, reject) {
        const tokenID = getToken();

        const options = {
          method: "POST",
          headers: new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: BEARER_TOKEN + tokenID,
          }),
          body: body,
        };

        return fetch(url, options)
          .then((resp) => {
            return handleResponse(resp);
          })
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(createError(error));
          });
      });
    }
  };

  createEntryRewardingAction = (
    businessId,
    userId,
    fairId,
    getNewTokenCallback
  ) => {
    let url = this.createEntryRewardingActionURL.replace(
      "{business}",
      businessId
    );

    const tokenID = this.getToken();

    const createEntryRewardingAction = this.createEntryRewardingAction;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    let body = JSON.stringify({
      location: fairId,
      user_id: userId,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return createEntryRewardingAction(
                  businessId,
                  userId,
                  fairId,
                  null
                );
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  createRewardingAction = (
    type,
    merchantId,
    productId,
    expense,
    comment,
    date,
    location,
    getNewTokenCallback
  ) => {
    let url = this.createRewardingActionURL;

    const tokenID = this.getToken();

    const createRewardingAction = this.createRewardingAction;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    let body = JSON.stringify({
      type: type,
      business_id: merchantId,
      product_id: productId,
      expense: expense,
      comment: comment,
      location: location,
      date: date,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return createRewardingAction(
                  type,
                  merchantId,
                  productId,
                  expense,
                  comment,
                  date,
                  null
                );
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getUserProductDefault = (getNewTokenCallback) => {
    let url = this.getUserProductDefaultURL;
    const getUserProductDefault = this.getUserProductDefault;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    const saveToken = this.saveToken;
    const userID = this.getTokenInfo().bme_id;

    url = url.replace("{user}", userID);
    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
          return;
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getUserProductDefault(null);
              })
              .then((data) => {
                resolve(data);
                return;
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  //** Generic GET request
  makeGetRequest = (url, getNewTokenCallback) => {
    const tokenID = this.getToken();

    const makeGetRequest = this.makeGetRequest;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    url = this.baseURL + url;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      //non controllo in cache

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return makeGetRequest(url, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getUser = (userID, getNewTokenCallback) => {
    let url = this.getUserURL;
    const getUser = this.getUser;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    url = url.replace("{user}", userID);
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
          return;
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getUser(userID);
              })
              .then((data) => {
                resolve(data);
                return;
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  changeUserStatus = (userID, action, getNewTokenCallback) => {
    let url;
    if (action === "ENABLE") {
      url = this.getEnableUserURL;
      url = url.replace("{user}", userID);
      url = url.replace("{aud}", "gdsp-x-bme");
    } else {
      url = this.getDisableUserURL;
      url = url.replace("{user}", userID);
      url = url.replace("{aud}", "gdsp-x-bme");
    }

    const changeUserStatus = this.changeUserStatus;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    const saveToken = this.saveToken;

    url = url.replace("{user}", userID);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
          return;
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return changeUserStatus(userID, action, null);
              })
              .then((data) => {
                resolve(data);
                return;
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getAllRewardingActions = (userID, getNewTokenCallback) => {
    let url = this.getAllRewardingActionsURL;
    const getAllRewardingActions = this.getAllRewardingActions;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    //const userID = this.getTokenInfo().bme_id
    const saveToken = this.saveToken;

    url = url.replace("{user}", userID);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getAllRewardingActions(userID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getBusinessRewardingAction = (business, status, getNewTokenCallback) => {
    let url = this.getBusinessRewardingActionsURL;
    const getBusinessRewardingAction = this.getBusinessRewardingAction;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    const saveToken = this.saveToken;

    url = url.replace("{business}", business);
    if (status && status !== null && status !== "")
      url = url + "&status=" + status;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
          return;
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getBusinessRewardingAction(status, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getGenListCategories = () => {
    let url = this.getGenListCategoriesURL;
    url = url + "?tenant=" + this.defaultTenantID;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getEventsByCampaign = (eventCampaignId, getNewTokenCallback) => {
    let url = this.getEventsByCampaignURL;
    const getEventsByCampaign = this.getEventsByCampaign;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    const saveToken = this.saveToken;

    url = url.replace("{tenant}", this.defaultTenantID);
    url = url + "?main=" + eventCampaignId;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getEventsByCampaign(null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getRewadingActionQRCode = (rewardingActionID, getNewTokenCallback) => {
    let url = this.getRewadingActionQRCodeURL;
    const getRewadingActionQRCode = this.getRewadingActionQRCode;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    const saveToken = this.saveToken;

    url = url.replace("{raid}", rewardingActionID);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getRewadingActionQRCode(rewardingActionID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getFairRewadingActionQRCode = (fairKey, getNewTokenCallback) => {
    let url = this.getFairRewadingActionQRCodeURL;
    const getFairRewadingActionQRCode = this.getFairRewadingActionQRCode;
    const tokenExpired = this.checkTokenExpired;

    const token = this.getToken();
    const saveToken = this.saveToken;

    url = url.replace("{fair-key}", fairKey);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + token,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
          return;
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getFairRewadingActionQRCode(fairKey, null);
              })
              .then((data) => {
                resolve(data);
                return;
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getProfilePictureUploadUrl = (getNewTokenCallback) => {
    let url = this.getProfilePictureUploadUrlURL;
    const getProfilePictureUploadUrl = this.getProfilePictureUploadUrl;
    const tokenExpired = this.checkTokenExpired;

    const saveToken = this.saveToken;
    const tokenID = this.getToken();
    const userID = this.getTokenInfo().bme_id;

    url = url.replace("{user}", userID);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };
      return fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((c) => {
          resolve(c.upload_url);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getProfilePictureUploadUrl(null);
              })
              .then((url) => {
                resolve(url);
              })
              .catch((error) => reject(createError(error)));
          } else {
            reject(createError(error));
          }
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  uploadPictureRequest = (url, body) => {
    const options = {
      method: "POST",
      body: body,
    };
    return new Promise(function(resolve, reject) {
      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getUploadContentsUrl = (getNewTokenCallback) => {
    const url = this.getUploadContentsURL;
    const tokenID = this.getToken();
    //console.log(tokenID)

    const getUploadContentsUrl = this.getUploadContentsUrl;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getUploadContentsUrl(null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  updateContentStatus = (contentID, status, getNewTokenCallback) => {
    const url = this.updateContentStatusURL
      .replace("{content}", contentID)
      .replace("{status}", status);
    const tokenID = this.getToken();

    const updateContentStatus = this.updateContentStatus;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return updateContentStatus(contentID, status, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };
  getFairByQRCode = (code, getNewTokenCallback) => {
    const url = this.getFairByQRCodeURL.replace("{code}", code);
    const tokenID = this.getToken();

    const getFairByQRCode = this.getFairByQRCode;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback !== null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getFairByQRCode(code, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getActionByQRCode = (code, getNewTokenCallback) => {
    const url = this.getActionByQRCodeURL.replace("{code}", code);
    const tokenID = this.getToken();

    const getActionByQRCode = this.getActionByQRCode;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback !== null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getActionByQRCode(code, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  confirmAction = (code, actionID, business, getNewTokenCallback) => {
    const url = this.getConfirmActionURL.replace("{business}", business);
    const tokenID = this.getToken();

    const confirmAction = this.confirmAction;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    let body = JSON.stringify({
      qrcode: code,
      action_id: actionID,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback !== null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return confirmAction(code, actionID, business, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  cancelAction = (actionID, business, getNewTokenCallback) => {
    const url = this.getCancelActionURL.replace("{business}", business);
    const tokenID = this.getToken();

    const cancelAction = this.cancelAction;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    let body = JSON.stringify({
      action_id: actionID,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback !== null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return cancelAction(actionID, business, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  updateContent = (
    id,
    title,
    description,
    expiration,
    priority,
    type,
    customFields,
    businessId,
    getNewTokenCallback
  ) => {
    const url = this.updateContentURL.replace("{content}", id);
    const tokenID = this.getToken();

    const updateContent = this.updateContent;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    let body = JSON.stringify({
      title: title,
      expiration: expiration,
      description: description,
      priority: priority,
      custom_fields: customFields,
      type: type,
      business_id: businessId,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback !== null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return updateContent(
                  id,
                  title,
                  description,
                  expiration,
                  priority,
                  type,
                  customFields,
                  null
                );
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  //non occorre il token per questi contenuti
  getContent = (contentID) => {
    const url = this.getContentURL.replace("{content}", contentID);

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  deleteContent = (contentID, getNewTokenCallback) => {
    const url = this.deleteContentURL.replace("{content}", contentID);
    const tokenID = this.getToken();

    const deleteContent = this.deleteContent;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return deleteContent(contentID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  deleteReservation = (id, getNewTokenCallback) => {
    const url = this.deleteReservationURL.replace("{order}", id);
    const tokenID = this.getToken();

    const deleteReservation = this.deleteReservation;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "DELETE",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return deleteReservation(id, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  uploadContentRequest = (url, body) => {
    const options = {
      method: "POST",
      body: body,
    };

    return new Promise(function(resolve, reject) {
      //non controllo in cache

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getContents = (type, getNewTokenCallback) => {
    const url = this.getContentsURL + "?type=" + type;
    const tokenID = this.getToken();

    const getContents = this.getContents;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token

          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getContents(type, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getContentsPublic = (type) => {
    let url = this.getContentsPublicURL + "?type=" + type;
    url = url + "&tenant=" + this.defaultTenantID;
    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getProductCampaignPrizes = (campaignID, productID, getNewTokenCallback) => {
    let url = this.getProductCampaignPrizesURL;
    url = url.replace("{campaign}", campaignID);
    url = url.replace("{product}", productID);

    const tokenID = this.getToken();

    const getProductCampaignPrizes = this.getProductCampaignPrizes;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getProductCampaignPrizes(campaignID, productID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  issueProductPrize = (cardID, prizeID, getNewTokenCallback) => {
    const url = this.issueProductPrizeURL
      .replace("{card}", cardID)
      .replace("{prize}", prizeID);
    const tokenID = this.getToken();

    const issueProductPrize = this.issueProductPrize;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return issueProductPrize(cardID, prizeID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  downloadMerchantStats = (getNewTokenCallback) => {
    const tokenID = this.getToken();
    //const userID = this.getTokenInfo().bme_id

    let url = this.getDownloadMerchantStatURL;

    const downloadMerchantStats = this.downloadMerchantStats;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "text/csv",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((data) => {
          return handleDownload(data);
        })
        .then(resolve)
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return downloadMerchantStats(null);
              })
              .then(resolve)
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  downloadUserStats = (getNewTokenCallback) => {
    const tokenID = this.getToken();
    //const userID = this.getTokenInfo().bme_id

    let url = this.getDownloadStatURL;

    const downloadUserStats = this.downloadUserStats;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "text/csv",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((data) => {
          return handleDownload(data);
        })
        .then(resolve)
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return downloadUserStats(null);
              })
              .then(resolve)
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getProducts = (userID, getNewTokenCallback) => {
    const tokenID = this.getToken();
    //const userID = this.getTokenInfo().bme_id

    let url = this.productsURL;
    url = url.replace("{user}", userID);

    const getProducts = this.getProducts;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getProducts(userID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getAmazonGiftcardInfo = (couponID, getNewTokenCallback) => {
    const tokenID = this.getToken();

    let url = this.getAmazonGiftcardInfoURL;
    url = url.replace("{coupon}", couponID);

    const getAmazonGiftcardInfo = this.getAmazonGiftcardInfo;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getAmazonGiftcardInfo(couponID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  burnAmazonCoupon = (couponID, getNewTokenCallback) => {
    const tokenID = this.getToken();

    let url = this.getBurnAmazonCouponURL;
    url = url.replace("{coupon}", couponID);

    const burnAmazonCoupon = this.burnAmazonCoupon;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "PUT",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return burnAmazonCoupon(couponID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getProductQrcode = (cardID, getNewTokenCallback) => {
    const tokenID = this.getToken();

    let url = this.getProductQrcodeURL;
    url = url.replace("{product}", cardID);

    const getProductQrcode = this.getProductQrcode;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getProductQrcode(cardID, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  searchBusinessesByContentPublic = (contentId, categoryId) => {
    let url = this.searchBusinessByContentPublicURL.replace(
      "{content}",
      contentId
    );
    url = url + "?tenant=" + this.defaultTenantID;

    if (categoryId) url = url + "&category=" + categoryId;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(createError(error));
        });
    });
  };

  getBusinessContentSlots = (businessId, contentId, getNewTokenCallback) => {
    let url = this.getBusinessContentSlotsURL
      .replace("{business}", businessId)
      .replace("{content}", contentId);
    const tokenID = this.getToken();

    const getBusinessContentSlots = this.getBusinessContentSlots;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getBusinessContentSlots(businessId, contentId, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  postContactEmail = (message, date, bsId, getNewTokenCallback) => {
    let url = this.postContactEmailURL;
    const tokenID = this.getToken();

    const postContactEmail = this.postContactEmail;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    let body = JSON.stringify({
      type: "MAIL_TEMPLATE_CONTACT_QUOTE",
      user_message: message,
      business_id: bsId,
      meeting_time: date, // yyyy-mm-ddThh:mm
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return postContactEmail(message, date, bsId, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  postBusinessEcomOrders = (
    businessId,
    contentId,
    date,
    slotId,
    notes,
    getNewTokenCallback
  ) => {
    let url = this.postBusinessEcomOrdersURL.replace("{business}", businessId);
    const tokenID = this.getToken();

    const postBusinessEcomOrders = this.postBusinessEcomOrders;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    const userId = getUserInfo()?.user_id;

    let body = JSON.stringify({
      customer_id: userId,
      date: date,
      slot_id: slotId,
      content_id: contentId,
      notes: notes,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return postBusinessEcomOrders(
                  businessId,
                  contentId,
                  date,
                  slotId,
                  notes,
                  null
                );
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getEcomOrders = (contentId, getNewTokenCallback) => {
    const userId = getUserInfo()?.user_id;

    let url = this.getEcomOrdersURL
      .replace("{user}", userId)
      .replace("{content}", contentId);
    const tokenID = this.getToken();

    const getEcomOrders = this.getEcomOrders;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getEcomOrders(contentId, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  getEventsConfirmed = (campaignId, getNewTokenCallback) => {
    const userId = getUserInfo()?.user_id;

    let url = this.getEventsConfirmedURL.replace("{user}", userId);
    url = url + "?mainEventId=" + campaignId;

    const tokenID = this.getToken();

    const getEventsConfirmed = this.getEventsConfirmed;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    return new Promise(function(resolve, reject) {
      let options = {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return getEventsConfirmed(campaignId, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };

  postEventConfirm = (eventId, getNewTokenCallback) => {
    let url = this.postEventConfirmURL.replace("{campaign}", eventId);
    const tokenID = this.getToken();

    const postEventConfirm = this.postEventConfirm;
    const tokenExpired = this.checkTokenExpired;
    const saveToken = this.saveToken;

    const userId = getUserInfo()?.user_id;

    let body = JSON.stringify({
      user: userId,
      quantity: 1,
      notification_type: 0,
    });

    return new Promise(function(resolve, reject) {
      let options = {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: BEARER_TOKEN + tokenID,
        }),
        body: body,
      };

      fetch(url, options)
        .then((resp) => {
          return handleResponse(resp);
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          //check expire token
          if (getNewTokenCallback != null && tokenExpired(error)) {
            getNewTokenCallback()
              .then((token) => {
                saveToken(token);
                return postEventConfirm(eventId, null);
              })
              .then((data) => {
                resolve(data);
              })
              .catch((err) => {
                reject(createError(err));
              });
          } else {
            reject(createError(error));
          }
        });
    });
  };
}

export default BmarkenAPI;
