import React, { useContext, useEffect, useState } from "react";
import Topbar from "../common_components/Topbar";
import { ROUTES } from "../constants";
import { withBmarkenAPI } from "../utils/Api";
import { withFirebase } from "../utils/Firebase";
import { useHistory } from "react-router-dom";
import { fullMobileWidth } from "../styles/helpers";
import { translateError } from "../utils/utils";
import { SharedContext } from "../utils/Session";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import moment from "moment";
import SposiIcon from "../common_components/SposiIcon";
import LoadingOverlay from "../common_components/LoadingOverlay";
import ConfirmPopUp from "../common_components/ConfirmPopUp";

const byDate = (a, b) =>
  new Date(a.planned_delivery) - new Date(b.planned_delivery);

function combineDateAndTime(start_date, start_hour) {
  const parsedDate = moment(start_date);

  // Extract hours and minutes from start_hour
  const [hour, minute] = start_hour.split(":").map(Number);

  // Set the extracted hour and minute to the parsedDate
  parsedDate.hour(hour);
  parsedDate.minute(minute);

  return parsedDate;
}

const AgendaAppuntamenti = (props) => {
  const history = useHistory();
  const [reservations, setReservations] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState(null);
  const { dispatchMessage } = useContext(SharedContext);

  const loadAgenda = async () => {
    setLoading(true);
    let passList = [],
      reservationList = [];
    props.bmarkenAPI
      .getEcomOrders(props.selectedFair.id, () =>
        props.firebase.doRefreshToken()
      )
      .then((resp) => {
        reservationList = resp || [];
        return props.bmarkenAPI.getEventsConfirmed(
          props.selectedFair.event_campaign_id,
          () => props.firebase.doRefreshToken()
        );
      })
      .then((resp) => {
        if (resp?.length && props.selectedFair?.event_campaign_id) {
          passList = resp;
          return props.bmarkenAPI.getEventsByCampaign(
            props.selectedFair?.event_campaign_id,
            () => props.firebase.doRefreshToken()
          );
        } else {
          return null;
        }
      })
      .then((resp) => {
        if (passList) {
          const er = passList.map((pass) => {
            const eventCampaign = resp?.find(
              (ec) => ec.id === pass.campaign_id
            );
            let plannedDelivery;
            if (eventCampaign) {
              plannedDelivery = combineDateAndTime(
                eventCampaign.start_date,
                eventCampaign.start_hour
              );
            }
            return {
              planned_delivery: plannedDelivery,
              business_name: pass.campaign_name,
              event: true,
              description: eventCampaign.description,
              image: eventCampaign.image_url,
            };
          });
          reservationList = [...reservationList, ...er];
          setReservations(reservationList);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  useEffect(() => {
    loadAgenda();
  }, []);

  const handleDelete = (res) => {
    setLoading(true);
    setReservation(null);

    props.bmarkenAPI
      .deleteReservation(res.id, () => {
        return props.firebase.doRefreshToken(true);
      })
      .then(() => {
        loadAgenda();
        setLoading(false);
        dispatchMessage("Prenotazione rimossa con successo", "info");
      })
      .catch((error) => {
        setLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  return (
    <div style={fullMobileWidth}>
      <div>
        <Topbar history={history} goBack={() => props.goTo("fair")} />
      </div>
      <div>
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          alt="logoFiera"
          src={props.selectedFair.url}
        />
      </div>
      <SposiIcon iconName="agenda" active={true} main={true} />
      <Typography color="primary" variant="h6">
        L'agenda dei tuoi appuntamenti
      </Typography>
      {!reservations?.length && !loading && (
        <Typography>Non hai appuntamenti in questa fiera</Typography>
      )}
      {reservations?.length > 0 && (
        <List>
          {reservations.sort(byDate).map((item) => (
            <ListItem
              divider
              key={item.id}
              button
              onClick={() => {
                setReservation(item);
                setOpenDialog(true);
              }}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    <Typography
                      style={{
                        color: "green",
                        fontWeight: 700,
                      }}
                    >
                      {item.event ? "Evento" : "Appuntamento"}
                    </Typography>
                    <Typography>
                      {item.planned_delivery
                        ? moment(item.planned_delivery).format(
                            "dddd" + " " + "LL"
                          )
                        : ""}
                    </Typography>

                    <Typography>
                      {item.planned_delivery
                        ? "Ore " + moment(item.planned_delivery).format("LT")
                        : ""}
                    </Typography>
                    <Typography color="primary">
                      {item.business_name}
                    </Typography>
                  </React.Fragment>
                }
              />

              <ListItemSecondaryAction>
                {!item.event && (
                  <IconButton
                    onClick={() => {
                      setOpenConfirm(true);
                      setReservation(item);
                    }}
                    edge="end"
                  >
                    <Delete />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}

      <ConfirmPopUp
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
          setReservation(null);
        }}
        onConfirm={() => handleDelete(reservation)}
        text={"Confermi di voler cancellare la prenotazione?"}
        title="Conferma cancellazione"
      />

      {reservation && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDialog}
          onClose={() => {
            setReservation(null);
            setOpenDialog(false);
          }}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent>
            <DialogContentText>{reservation.notes}</DialogContentText>
            <DialogContentText>
              {reservation.planned_delivery
                ? moment(reservation.planned_delivery).format(
                    "dddd" + " " + "LL"
                  )
                : ""}{" "}
              {reservation.planned_delivery
                ? "Ore " + moment(reservation.planned_delivery).format("LT")
                : ""}
            </DialogContentText>
            <DialogContentText>{reservation.business_name}</DialogContentText>
            {!reservation.event && (
              <DialogContentText>
                {"Note: "}
                {reservation.notes || "-"}
              </DialogContentText>
            )}
            {reservation.event && (
              <>
                {reservation.image && (
                  <img
                    style={{
                      width: "100%",
                      marginRight: 10,
                    }}
                    src={reservation.image}
                  />
                )}
                <DialogContentText>{reservation.description}</DialogContentText>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setReservation(null);
                setOpenDialog(false);
              }}
              color="primary"
              variant="contained"
            >
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <LoadingOverlay show={loading} />
    </div>
  );
};

export default withFirebase(withBmarkenAPI(AgendaAppuntamenti));
